<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Upload update')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form
        class="form"
        @submit.prevent="submitForm"
      >
        <div class="form-group">
          <label>Version <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text w-50px">
                <i class="mx-auto">
                  <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
                </i>
              </span>
            </div>
            <input
              ref="versionInput"
              type="text"
              :class="['form-control', { 'is-invalid': $v.update.version.$error }]"
              :disabled="isLoadingForm"
              placeholder="X.X.X"
              v-model.trim="$v.update.version.$model"
            />
          </div>
          <span class="form-text text-muted">The version of the update using <a href="https://semver.org/" target="_blank">Semantic Versioning.</a></span>
        </div>

        <div class="form-group">
          <label>Changelog <sup>Required</sup></label>
          <div class="input-group">
            <textarea
              :class="['form-control', { 'is-invalid': $v.update.description.$error }]"
              :disabled="isLoadingForm"
              rows="5"
              cols="10"
              v-model.trim="$v.update.description.$model"
            />
          </div>
          <span class="form-text text-muted">Changes in this version.</span>
        </div>

        <div class="form-group">
          <label>File <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text w-50px">
                <i class="mx-auto">
                  <font-awesome-icon :icon="['fas', 'file-archive']" />
                </i>
              </span>
            </div>
            <label
              for="upload-file"
              :class="['form-control cursor-pointer', {
                'is-invalid': $v.file.$error,
                'disabled': isLoadingForm,
                'text-muted': !file
              }]"
              :disabled="isLoadingForm"
            >
              {{ file ? file.name : 'Choose file...' }}
            </label>
            <div class="input-group-append">
              <label class="input-group-text cursor-pointer" for="upload-file">
                Browse
              </label>
            </div>
          </div>
          <span class="form-text text-muted">The update file itself.</span>

          <!-- begin::File input -->
          <!-- File input isn't visible and is fixed by the label above -->
          <input
            id="upload-file"
            type="file"
            class="d-none"
            :disabled="isLoadingForm"
            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
            @change="onFileChange($event.target.files)"
          />
          <!-- end::File input -->
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">
        Upload
      </button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import { semver } from '@/libs/validators';

import Update from '@/libs/classes/update';

export default {
  mixins: [Mixins.Modal],
  name: 'uploadUpdate',
  data () {
    return {
      options: {},
      isLoadingForm: false,

      update: null,
      file: null,
    };
  },
  validations: {
    update: {
      version: {
        required,
        semver,
      },
      description: {
        required,
      },
    },
    file: {
      required,
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:updates:upload:open', this.open);
    this.$eventhub.on('modals:updates:upload:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:updates:upload:open', this.open);
    this.$eventhub.off('modals:updates:upload:close', this.close);
  },

  methods: {
    onFileChange (files = null) {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      this.$set(this, 'file', file);
    },

    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.update, this.file);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      // Reset form
      this.$set(this, 'update', ((options && options.update) || new Update()));
      this.$set(this, 'file', null);
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();

      this.$refs.versionInput.focus();
    },
  },
};
</script>
