<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t(`updates.types.${type}.title`) }}</h3>
        <span class="text-muted font-weight-bold font-size-sm">{{ $t(`updates.types.${type}.description`) }}</span>
      </div>

      <div class="card-toolbar">
        <button
          v-if="$auth.hasPermission('updates.create')"
          class="btn btn-primary font-weight-bolder mr-3"
          @click.prevent="openUploadModal()"
        >
          <span class="svg-icon align-middle">
            <font-awesome-icon :icon="['fas', 'upload']" />
          </span>
          <span>Upload</span>
        </button>
      </div>
    </div>

    <div class="card-body">
      <div class="row border-bottom">
        <!-- begin::Published version -->
        <div class="col-12 col-md text-center">
          <div class="form-group">
            <label class="text-upper">Published release</label>
            <div>
              <span :class="['label w-100px label-inline label-xl label-rounded p-5', (overview.published ? 'label-light-success' : 'label-secondary')]">
                {{ (overview.published && overview.published.version) || 'N/A' }}
              </span>
            </div>
          </div>
        </div>
        <!-- end::Published version -->

        <!-- begin::Latest version -->
        <div class="col-12 col-md text-center">
          <div class="form-group">
            <label class="text-upper">Latest release</label>
            <div>
              <span :class="['label w-100px label-inline label-xl label-rounded p-5', (overview.latest ? 'label-light-info' : 'label-secondary')]">
                {{ (overview.latest && overview.latest.version) || 'N/A' }}
              </span>
            </div>
          </div>
        </div>
        <!-- end::Latest version -->
      </div>

      <div class="row mt-5">
        <!-- begin::Datatable actions -->
        <div class="col-12 mb-7">
          <div class="row align-items-center">
            <div class="col-12 col-lg">
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs.datatable.search"
                  @keyup="searchDebounced"
                >
                <span>
                  <i>
                    <font-awesome-icon :icon="['fas', 'search']" />
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Datatable actions -->

        <!-- begin::Datatable -->
        <datatable
          class="col-12"
          ref="datatable"
          :url="datatable.url"
          :fields="datatable.fields"
          :filters="datatable.filters"
          :sort-order="datatable.sortOrder"
          :search-fields="datatable.searchFields"
          :on-pagination-data="onPaginationData"

          :transform="transform"
        >
          <!-- begin::Status -->
          <div slot="status" slot-scope="props">
            <span
              v-for="(label, idx) in [getStateLabel(props.rowData)]"
              :key="`update.${props.rowData._meta.guid}.state.${idx}`"
              :class="['label w-100 label-inline label-xl label-rounded p5', `label-${label && label.variant || 'secondary'}`]"
              v-html="label && label.text || 'Unknown'"
            />
          </div>
          <!-- end::Status -->

          <!-- begin::Version -->
          <div slot="version" slot-scope="props">
            <span
              :class="['label w-100 label-inline label-xl label-rounded p5 label-light-info']"
              v-html="props.rowData.version"
            />
          </div>
          <!-- end::Version -->

          <!-- begin::Actions -->
          <div slot="actions" slot-scope="props">
            <!-- begin::Files dropdown -->
            <b-dropdown
              :ref="`${props.rowData._meta.guid}.filesDropdown`"
              variant="clean btn-sm btn-icon"
              class="dropdown-inline"
              right
              lazy
              no-caret
              boundary="window"
              menu-class="dropdown-menu-lg px-8 py-5"
            >
              <template v-slot:button-content>
                <span class="svg-icon">
                  <font-awesome-icon :icon="['fas', 'info-circle']" />
                </span>
              </template>

              <template v-slot:default>
                <div class="row">
                  <div class="col-12 pb-1 border-bottom">
                    <span class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary">Update contents</span>
                  </div>

                  <div class="col-12">
                    <div class="row">
                      <!-- begin::Files -->
                      <template v-if="props.rowData.files && props.rowData.files.length > 0">
                        <div
                          v-for="file in props.rowData.files"
                          :key="`update.${props.rowData._meta.guid}.files.${file.file}`"
                          class="col-12 d-flex align-items-center mt-3"
                        >
                          <div class="symbol symbol-30 symbol-light-info mr-3">
                            <span class="symbol-label">
                              <span class="svg-icon svg-icon-primary">
                                <font-awesome-icon :icon="['fas', 'file']" />
                              </span>
                            </span>
                          </div>
                          <div class="d-flex flex-column font-weight-bold">
                            <span class="text-dark text-hover-primary">{{ $tu(`updates.types.${props.rowData.type}.files.${file.type}`) || file.type }}: <span class="text-info">{{ file.version }}</span></span>
                            <span class="text-muted font-size-xs">File {{ file.file }}</span>
                          </div>
                        </div>
                      </template>
                      <!-- end::Files -->
                    </div>
                  </div>
                </div>
              </template>
            </b-dropdown>
            <!-- end::Files dropdown -->

            <!-- begin::Actions dropdown -->
            <b-dropdown
              :ref="`${props.rowData._meta.guid}.actionsDropdown`"
              variant="clean btn-sm btn-icon"
              class="dropdown-inline ml-2"
              right
              lazy
              no-caret
              boundary="window"
              menu-class="dropdown-menu-md"
            >
              <template v-slot:button-content>
                <span class="svg-icon">
                  <font-awesome-icon :icon="['fas', 'cog']" />
                </span>
              </template>

              <template v-slot:default>
                <ul class="navi flex-column navi-hover py-2">
                  <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                    Choose an action:
                  </li>

                  <li class="navi-separator my-0" />

                  <template>
                    <li class="navi-item">
                      <a class="navi-link cursor-pointer" @click.prevent="downloadDocument(props.rowData)">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'download']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Download files</span>
                      </a>
                    </li>
                  </template>

                  <!-- begin::Actions - Publish -->
                  <template v-if="$auth.hasPermission('updates.update') && ['draft', 'beta'].includes(props.rowData.state)">
                    <li class="navi-separator my-0" />

                    <li class="navi-item">
                      <a class="navi-link cursor-pointer text-hover-success" @click.prevent="publishUpdate(props.rowData)">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'check']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Publish update</span>
                      </a>
                    </li>
                  </template>
                  <!-- end::Actions - Publish -->

                  <!-- begin::Actions - Revoke -->
                  <template v-if="$auth.hasPermission('updates.update') && props.rowData.state !== 'revoked'">
                    <li class="navi-separator my-0" />

                    <li class="navi-item">
                      <a class="navi-link cursor-pointer" @click.prevent="revokeUpdate(props.rowData)">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'trash-undo']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Revoke update</span>
                      </a>
                    </li>
                  </template>
                  <!-- end::Actions - Revoke -->

                  <template v-if="$auth.hasPermission('updates.delete')">
                    <li class="navi-separator my-0" />

                    <li class="navi-item">
                      <a class="navi-link cursor-pointer text-hover-danger" @click.prevent="removeDocument(props.rowData)">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'trash']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Remove update</span>
                      </a>
                    </li>
                  </template>
                </ul>
              </template>
            </b-dropdown>
            <!-- end::Actions dropdown -->
          </div>
          <!-- end::Actions -->
        </datatable>
        <!-- end::Datatable -->

        <!-- begin::Datatable footer -->
        <div class="col datatable datatable-default">
          <div class="datatable-pager datatable-paging-loaded">
            <datatable-pagination
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
            />

            <datatable-pagination-info
              ref="pagination.info"
              @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
            />
          </div>
        </div>
        <!-- end::Datatable footer -->
      </div>
    </div>

    <!-- begin::Modals -->
    <upload-modal ref="uploadModal" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { DatatableMixin } from '@vedicium/metronic-vue';

import uploadModal from '@/components/pages/admin/updates/type/modals/upload.modal.vue';

import Update from '@/libs/classes/update';

export default {
  mixins: [DatatableMixin],
  components: {
    uploadModal,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      type: this.$route.params.type,
      overview: {
        published: null,
        latest: null,
      },

      datatable: {
        url: Update.uri,

        fields: [{
          name: 'status',
          title: 'Status',
          sortField: 'state',
          titleClass: 'w-100px',
          dataClass: 'w-100px align-top',
        }, {
          name: 'version',
          title: 'Version',
          sortField: 'version',
          titleClass: 'w-100px',
          dataClass: 'w-100px align-top',
        }, {
          name: 'description',
          title: 'Changelog',
          sortField: 'description',
          titleClass: 'w-400px',
          dataClass: 'w-400px align-top text-prewrap',
        }, {
          name: '_meta.created',
          title: 'Uploaded',
          sortField: '_meta.created',
          titleClass: 'w-150px',
          dataClass: 'w-150px align-top',
          formatter (value) {
            return moment(value).format('LLL');
          },
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-100px',
          dataClass: 'w-100px align-top',
        }],

        sortOrder: [{
          field: '_meta.created',
          direction: 'desc',
        }],

        filters: {
          type: this.$route.params.type,
        },

        searchFields: ['version', 'description'],
      },
    };
  },

  async mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Admin' }, { title: 'Updates', route: '/admin/updates' }, { title: this.$t(`updates.types.${this.type}.title`) }]);

    try {
      await this.getUpdateOverview();
    } catch (e) {
      this.$errors.handle(e, { ui_element: true });
      console.error(e);
    }
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new Update().merge(row));
      }
      return response;
    },

    getStateLabel (update = null) {
      const label = { variant: 'secondary', text: this.$tu(`updates.states.${update.state}.title`) };

      switch (update.state) {
        case 'published': {
          label.variant = 'light-success';
          break;
        }

        case 'beta': {
          label.variant = 'light-info';
          break;
        }

        case 'revoked': {
          label.variant = 'light-danger';
          break;
        }

        default: {
          break;
        }
      }

      return label;
    },

    async getUpdateOverview (options = {}) {
      await Promise.all([
        // Get latest published update
        (async () => {
          const response = await this.$ws.get(Update.uri, {
            ...(options || {}),
            query: {
              ...(options.query || {}),
              filter: {
                state: ['published'],
              },
              sort: '_meta.created|desc',
              limit: 1,
            },
          });

          if (!response || response.length === 0) {
            this.$set(this.overview, 'published', null);
            return;
          }

          this.$set(this.overview, 'published', new Update().merge(response[0]));
        })(),

        // Get latest update
        (async () => {
          const response = await this.$ws.get(Update.uri, {
            ...(options || {}),
            query: {
              ...(options.query || {}),
              filter: {
                state: ['beta', 'published'],
              },
              sort: '_meta.created|desc',
              limit: 1,
            },
          });

          if (!response || response.length === 0) {
            this.$set(this.overview, 'latest', null);
            return;
          }

          this.$set(this.overview, 'latest', new Update().merge(response[0]));
        })(),
      ]);
    },

    async downloadDocument (document = null) {
      if (!document || document instanceof Update === false) {
        return;
      }

      // Hide dropdown, if not hidden yet
      if (this.$refs[`${document._meta.guid}.actionsDropdown`]) {
        this.$refs[`${document._meta.guid}.actionsDropdown`].hide();
      }

      this.$refs.datatable.setLoading(true);
      try {
        await document.download();
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },

    async publishUpdate (document = null) {
      if (!document || document instanceof Update === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Publish update',
        message: `Are you sure that you want to publish update <strong>${this.$t(`updates.types.${document.type}.title`)} - v${document.version}</strong>?<br />This will revoke the update, but not delete the source files.`,
        variant: 'success',
        buttonText: 'Publish',
        async onSubmit () {
          try {
            await document.update([{ action: 'set_field', field: 'state', value: 'published' }]);
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
          vm.getUpdateOverview({ query: { disable_cache: true } });
        },
      });
    },

    async revokeUpdate (document = null) {
      if (!document || document instanceof Update === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Revoke update',
        message: `Are you sure that you want to revoke update <strong>${this.$t(`updates.types.${document.type}.title`)} - v${document.version}</strong>?<br />This will revoke the update, but not delete the source files.`,
        buttonText: 'Revoke',
        async onSubmit () {
          try {
            await document.update([{ action: 'set_field', field: 'state', value: 'revoked' }]);
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
          vm.getUpdateOverview({ query: { disable_cache: true } });
        },
      });
    },

    async removeDocument (document = null) {
      if (!document || document instanceof Update === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove update',
        message: `Are you sure that you want to remove update <strong>${this.$t(`updates.types.${document.type}.title`)} - v${document.version}</strong>?<br />This will remove the update from the system and delete the source files.`,
        buttonText: 'Remove',
        async onSubmit () {
          try {
            await document.remove();
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
          vm.getUpdateOverview({ query: { disable_cache: true } });
        },
      });
    },

    openUploadModal () {
      const vm = this;
      vm.$eventhub.emit('modals:updates:upload:open', {
        title: `Upload ${this.$t(`updates.types.${this.type}.title`)}`,
        update: new Update().merge({
          type: this.type,
        }),

        async onSubmit (update = null, file = null) {
          // Create formData, which is needed for an upload
          const formData = new FormData();
          formData.append('file', file);

          // Add update data
          formData.append('type', update.type);
          formData.append('version', update.version);
          formData.append('description', update.description);

          // Upload update
          await vm.$http.post('/v2/updates', {
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          vm.$refs.datatable.reload({ query: { disable_cache: true } });
          vm.getUpdateOverview({ query: { disable_cache: true } });
        },
      });
    },
  },
};
</script>
